import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MeetingsState } from './types';
import {
  getApiCallInitialState,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { IAbridgedMeeting, IMeeting, MinutesStatus } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: MeetingsState = {
  loadMeetings: getApiCallInitialState<IAbridgedMeeting[]>('list'),
  createCommittee: getApiCallInitialState(),
  createMeeting: getApiCallInitialState(),
  loadMeeting: getApiCallInitialState<IMeeting | undefined>(),
  updateMeetingSettings: getApiCallInitialState(),
  deleteMeeting: getApiCallInitialState(),
  saveDocument: uploadApiCallInitialState,
  deleteDocument: getApiCallInitialState(),
  reorderDocuments: getApiCallInitialState(),
  createMeetingUpdate: getApiCallInitialState(),
  deleteMeetingUpdate: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    loadMeetingsInit(state, action: PayloadAction<void>) {
      state.loadMeetings = initialState.loadMeetings;
    },
    loadMeetingsRequest(
      state,
      action: PayloadAction<{
        organisation_id: string | number;
        committee_id: string | number;
      }>,
    ) {
      state.loadMeetings.loading = true;
      state.loadMeetings.error = undefined;
      state.loadMeetings.touched = true;
    },
    loadMeetingsSuccess(
      state,
      action: PayloadAction<{ data: IAbridgedMeeting[] }>,
    ) {
      state.loadMeetings.loading = false;
      state.loadMeetings.error = undefined;
      state.loadMeetings.data = action.payload.data;
    },
    loadMeetingsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMeetings.loading = false;
      state.loadMeetings.error = action.payload;
    },
    loadMeetingInit(state, action: PayloadAction<void>) {
      state.loadMeeting = initialState.loadMeeting;
    },

    loadMeetingRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadMeeting.loading = true;
      state.loadMeeting.error = undefined;
    },
    loadMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = undefined;
      state.loadMeeting.data = action.payload.data;
    },
    loadMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = action.payload;
    },
    updateMeetingSettingsRequest(
      state,
      action: PayloadAction<{
        id: number | string;
        publish?: boolean;
        notify_on_updates?: boolean;
        log_updates?: boolean;
        minutes_status?: MinutesStatus;
        previous_minutes_status?: MinutesStatus;
      }>,
    ) {
      state.updateMeetingSettings.loading = true;
      state.updateMeetingSettings.error = undefined;
    },
    updateMeetingSettingsSuccess(
      state,
      action: PayloadAction<{ data: IMeeting }>,
    ) {
      state.updateMeetingSettings.loading = false;
      state.updateMeetingSettings.error = undefined;
      state.updateMeetingSettings.data = action.payload.data;
    },
    updateMeetingSettingsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.updateMeetingSettings.loading = false;
      state.updateMeetingSettings.error = action.payload;
    },
    deleteMeetingRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteMeeting.loading = true;
      state.deleteMeeting.error = undefined;
    },
    deleteMeetingSuccess(state, action: PayloadAction<any>) {
      state.deleteMeeting.loading = false;
      state.deleteMeeting.error = undefined;
      state.deleteMeeting.data = action.payload.data;
    },
    deleteMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteMeeting.loading = false;
      state.deleteMeeting.error = action.payload;
    },
    saveDocumentRequest(state, action: PayloadAction<any>) {
      state.saveDocument.loading = true;
      state.saveDocument.error = undefined;
    },
    saveDocumentSuccess(state, action: PayloadAction<any>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = undefined;
      state.saveDocument.data = action.payload;
    },
    saveDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = action.payload;
    },
    saveDocumentProgress(state, action: PayloadAction<{ progress: number }>) {
      state.saveDocument.progress = action.payload.progress;
    },
    deleteDocumentRequest(state, action: PayloadAction<any>) {
      state.deleteDocument.loading = true;
      state.deleteDocument.error = undefined;
    },
    deleteDocumentSuccess(state, action: PayloadAction<any>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = undefined;
      state.deleteDocument.data = action.payload;
    },
    deleteDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = action.payload;
    },
    reorderDocumentsRequest(state, action: PayloadAction<any>) {
      state.reorderDocuments.loading = true;
      state.reorderDocuments.error = undefined;
    },
    reorderDocumentsSuccess(state, action: PayloadAction<any>) {
      state.reorderDocuments.loading = false;
      state.reorderDocuments.error = undefined;
      state.reorderDocuments.data = action.payload;
    },
    reorderDocumentsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.reorderDocuments.loading = false;
      state.reorderDocuments.error = action.payload;
    },
    createMeetingUpdateRequest(state, action: PayloadAction<any>) {
      state.createMeetingUpdate.loading = true;
      state.createMeetingUpdate.error = undefined;
    },
    createMeetingUpdateSuccess(state, action: PayloadAction<any>) {
      state.createMeetingUpdate.loading = false;
      state.createMeetingUpdate.error = undefined;
      state.createMeetingUpdate.data = action.payload;
    },
    createMeetingUpdateFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.createMeetingUpdate.loading = false;
      state.createMeetingUpdate.error = action.payload;
    },
    deleteMeetingUpdateRequest(
      state,
      action: PayloadAction<{ meeting_id: number; meeting_update_id: number }>,
    ) {
      state.deleteMeetingUpdate.loading = true;
      state.deleteMeetingUpdate.error = undefined;
    },
    deleteMeetingUpdateSuccess(state, action: PayloadAction<any>) {
      state.deleteMeetingUpdate.loading = false;
      state.deleteMeetingUpdate.error = undefined;
      state.deleteMeetingUpdate.data = action.payload;
    },
    deleteMeetingUpdateFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.deleteMeetingUpdate.loading = false;
      state.deleteMeetingUpdate.error = action.payload;
    },
    createMeetingRequest(state, action: PayloadAction<Partial<IMeeting>>) {
      state.createMeeting.loading = true;
      state.createMeeting.error = undefined;
    },
    createMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.createMeeting.loading = false;
      state.createMeeting.error = undefined;
      state.createMeeting.data = action.payload.data;
    },
    createMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.createMeeting.loading = false;
      state.createMeeting.error = action.payload;
    },
    createCommitteeRequest(
      state,
      action: PayloadAction<{
        organisation_id: number;
        name: string;
      }>,
    ) {
      state.createCommittee.loading = true;
      state.createCommittee.error = undefined;
    },
    createCommitteeSuccess(state, action: PayloadAction<{ data: any }>) {
      state.createCommittee.loading = false;
      state.createCommittee.error = undefined;
      state.createCommittee.data = action.payload.data;
    },
    createCommitteeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.createCommittee.loading = false;
      state.createCommittee.error = action.payload;
    },
  },
});

export const { actions: meetingsActions } = slice;
