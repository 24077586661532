import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { RiTimeLine } from 'react-icons/ri';

interface Props {
  title: string;
}

export const TimerSubtitle = (props: Props) => {
  const { title } = props;
  return (
    <Stack direction={'row'} spacing={1} sx={{ color: 'grey.600' }}>
      <Box>
        <RiTimeLine />
      </Box>
      <Typography variant="body2" sx={{ pt: 0.3 }}>
        {title}
      </Typography>
    </Stack>
  );
};
