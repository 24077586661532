import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useCallback } from 'react';
import { useCommitteesSlice } from '../app/pages/Committees/slice/hook';
import { selectCommittees } from '../app/pages/Committees/slice/selectors';

export function useLoadCommittees(organisationId) {
  const { actions: committeeActions } = useCommitteesSlice();
  const { loadCommittees } = useSelector(selectCommittees);
  const dispatch = useDispatch();

  const reloadCommittees = useCallback(() => {
    dispatch(
      committeeActions.loadCommitteesRequest({
        organisation_id: organisationId,
      }),
    );
  }, [dispatch, organisationId]);

  const hasCommittees = useMemo(() => {
    return !!loadCommittees.data?.length;
  }, [loadCommittees.data]);

  return { reloadCommittees, loadCommittees, hasCommittees };
}
