/**
 *
 * Committees
 *
 */
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { List } from './List';
import { Item } from './Item';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { SectionHeader } from 'app/components/SectionHeader';
import { Box, Button, Container, Stack } from '@mui/material';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import {
  CommitteeFormWidget,
  useCommitteeFormWidget,
} from 'app/widgets/CommitteeFormWidget';
import { RiAddCircleLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { useCommitteesSlice } from './slice/hook';

interface Props {
  organisation: IOrganisation;
}

export function Committees(props: Props) {
  const { organisation } = props;

  const { hasPermission } = useHasPermission();
  const { open } = useCommitteeFormWidget();

  const { actions } = useCommitteesSlice();
  const dispatch = useDispatch();

  const load = React.useCallback(() => {
    dispatch(
      actions.loadCommitteesRequest({
        organisation_id: organisation.id,
      }),
    );
  }, [organisation.id, dispatch]);
  if (!organisation) return null;

  return (
    <>
      <Helmet title={'My committees'}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>
      <SectionHeader
        title="Committees"
        cta={
          hasPermission(
            'manage-committees',
            'organisation',
            organisation.id,
          ) && (
            <>
              <Button
                color={'secondary'}
                startIcon={<RiAddCircleLine />}
                onClick={() => open({ organisation_id: organisation.id })}
              >
                Create a committee
              </Button>
              <CommitteeFormWidget
                onChange={() => {
                  load();
                  // load user data again to get committee perms
                  dispatch(authProviderActions.loadUserDataRequest());
                }}
              />
            </>
          )
        }
      />
      <Container>
        <Box my={4}>
          <Stack spacing={6}>
            <Routes>
              <Route path={''} element={<List organisation={organisation} />} />
              <Route
                path={':id/*'}
                element={<Item organisation={organisation} />}
              />
            </Routes>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
