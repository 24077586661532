/**
 *
 * ActionRegisterFormWidget
 *
 */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material';
import { AccessCommittee, IActionRegisterItem } from 'types/types';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { AddAction } from './AddAction';
import { CheckAndFinish } from './CheckAndFinish';
import { Link } from 'react-router-dom';
import key from 'weak-key';
import { AssignOwner } from './AssignOwner';
import { RiCloseLine } from 'react-icons/ri';
import { ActionRegisterCommittee } from '../slice/types';
import moment from 'moment-timezone';

interface Props {
  action?: IActionRegisterItem | undefined;
  onSave: (payload: ActionRegisterFormType) => void;
  onClose?: () => void;
  onDelete?: () => void;
  saving?: boolean;
  committees: ActionRegisterCommittee[];
  committee_id?: number | string;
  meeting_id?: number | string;
  organisation_id: number | string;
}

const schemaArr = [
  {
    description: Yup.string().required('This field is required'),
    committee_id: Yup.number().required('This field is required'),
    status: Yup.string().oneOf(['open', 'closed'], 'This field is required'),
    due_at: Yup.date()
      .typeError('Due date is required')
      .required('Due date is required'),
  },
  {},
  {},
];

export type ActionRegisterFormType = Partial<IActionRegisterItem> & {
  member_ids: (string | number)[];
};

export function ActionRegisterForm(props: Props) {
  const theme = useTheme();

  const {
    action,
    onSave,
    onClose,
    saving,
    organisation_id,
    committees,
    committee_id,
    meeting_id,
  } = props;

  const [step, setStep] = useState<number>(0);

  const steps = [
    {
      label: 'Add an action',
      component: (
        <AddAction
          onClose={onClose}
          committees={committees}
          next={() => setStep(1)}
        />
      ),
    },
    {
      label: 'Assign an owner',
      component: (
        <AssignOwner
          onClose={onClose}
          committees={committees}
          previous={() => setStep(0)}
          next={() => setStep(2)}
        />
      ),
    },
    {
      label: 'Check an finish',
      component: (
        <CheckAndFinish
          onClose={onClose}
          committees={committees}
          previous={() => setStep(1)}
          saving={saving}
        />
      ),
    },
  ];

  const formikRef = useRef(null);
  useEffect(() => {
    formikRef.current?.validateForm();
  }, [step]);

  const indexTab = steps.findIndex((tab, index) => index === step);
  const currentValidationSchema = Yup.object().shape(
    Object.assign({}, ...schemaArr.slice(0, indexTab + 1)),
  );

  return (
    <Formik<ActionRegisterFormType>
      innerRef={formikRef}
      enableReinitialize
      validateOnMount
      validationSchema={currentValidationSchema}
      initialValues={
        action
          ? {
              ...action,
              member_ids: action.members.map(m => m.id),
            }
          : {
              id: undefined,
              description: '',
              organisation_id,
              committee_id: committee_id || undefined,
              meeting_id: meeting_id || undefined,
              owner_names: [],
              due_at: '',
              status: 'open',
              member_ids: [],
            }
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          due_at: moment.utc(values.due_at).format('YYYY-MM-DD HH:mm:ss'),
        });
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting } = formik;
        if (isSubmitting && !saving) setSubmitting(false);
        return (
          <FormikForm>
            <Stack spacing={4}>
              <Box
                sx={{
                  mt: 2,
                  backgroundColor: 'grey.200',
                }}
              >
                <Stack alignItems="flex-end" p={2}>
                  <IconButton edge={'end'} size={'large'} onClick={onClose}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
                <Box sx={{ px: 4, pb: 4 }}>
                  <Stack spacing={6}>
                    <Stepper activeStep={indexTab}>
                      {steps.map((s, i) => (
                        <Step key={key(s)} active={step === i + 1}>
                          <StepLabel>{s.label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Stack spacing={4}>{steps[step].component}</Stack>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
