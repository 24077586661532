import { Chip, ChipProps, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface Props extends ChipProps {
  published: boolean;
  publishedText?: string;
  draftText?: string;
}

export function MeetingStatusChip(props: Props) {
  const {
    published,
    publishedText = 'Published to members',
    draftText = 'Draft - Not yet visible to members',
    sx,
    ...rProps
  } = props;
  return (
    <Chip
      label={published ? publishedText : draftText}
      color={published ? 'success' : 'warning'}
      sx={{ ...sx }}
      {...rProps}
    />
  );
}
