/**
 *
 * ProfilePage
 *
 */
import * as React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PersonalDetails } from '../PersonalDetails';
import { Conflicts } from '../Conflicts';
import { TrainingRecord } from '../TrainingRecord';
import { useSelector } from 'react-redux';
import { SecurityAndPasswords } from '../SecurityAndPasswords';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu, SectionMenuProps } from 'app/components/SectionMenu';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

interface Props {}

export function ProfilePage(props: Props) {
  const { loadUser } = useSelector(selectAuthProvider);
  const location = useLocation();

  if (!loadUser.data) {
    return <LoadingIndicator />;
  }

  const sections: Omit<SectionMenuProps, 'isActive'>[] = [
    {
      title: 'Personal details',
      path: '/profile/personal-details',
    },
    {
      title: 'Security & passwords',
      path: '/profile/security',
    },
    {
      title: 'Declarations of interest',
      path: '/profile/declarations',
    },
    {
      title: 'Academy record',
      path: '/profile/academy-record',
      hidden: !loadUser.data || !loadUser.data.thinkific_enabled,
    },
  ];

  return (
    <>
      <SectionHeader
        title="My profile"
        sectionMenu={
          <>
            {sections.map((section, index) => (
              <SectionMenu
                key={index}
                {...section}
                isActive={location.pathname === section.path}
              />
            ))}
          </>
        }
      />

      <Container>
        <Box my={4}>
          <Stack spacing={6}>
            <Routes>
              <Route path={'personal-details'} element={<PersonalDetails />} />
              <Route path={'security/*'} element={<SecurityAndPasswords />} />
              <Route path={'declarations/*'} element={<Conflicts />} />
              {/* <Route path={'skills/*'} element={<Skills />} /> */}
              <Route path={'academy-record/*'} element={<TrainingRecord />} />

              <Route
                path={'*'}
                element={<Navigate to={'personal-details'} replace />}
              />
            </Routes>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
