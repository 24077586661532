import * as React from 'react';
import { StartBoardValues, Wizard } from './Wizard';
import { IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { WizardContainer } from 'app/components/WizardContainer';
import { selectMeetings } from '../slice/selectors';
import { useMeetingsSlice } from '../slice/hook';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { useNavigate } from 'react-router-dom';
import { committeesActions } from 'app/pages/Committees/slice';
import { useCommitteesSlice } from 'app/pages/Committees/slice/hook';
import { useEffectOnce } from 'usehooks-ts';

interface Props {
  organisation: IOrganisation;
}

export function StartBoard(props: Props) {
  const { organisation } = props;

  const dispatch = useDispatch();
  const { actions: committeeActions } = useCommitteesSlice();
  const load = React.useCallback(() => {
    dispatch(
      committeeActions.loadCommitteesRequest({
        organisation_id: organisation.id,
      }),
    );
  }, [organisation.id, dispatch]);

  const { actions } = useMeetingsSlice();

  const navigate = useNavigate();

  const { createCommittee, createMeeting } = useSelector(selectMeetings);

  useHandleApiResponse(createCommittee, 'Committee created', {
    errorMessage: 'Unable to add Committee',
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(createMeeting, 'Meeting created', {
    errorMessage: 'Unable to add Meeting',
    onSuccess: () => {
      load();
      navigate(
        `/organisation/${organisation.id}/meetings/${createMeeting.data.id}`,
      );
    },
  });

  return (
    <WizardContainer>
      <Wizard
        saving={createCommittee.loading || createMeeting.loading}
        onSubmit={payload =>
          dispatch(
            actions.createMeetingRequest({
              organisation_id: +organisation.id,
              committee_name: payload.committee_name,
              ...payload.meeting,
            }),
          )
        }
        onSkip={payload => {
          dispatch(
            actions.createCommitteeRequest({
              organisation_id: +organisation.id,
              name: payload.committee_name,
            }),
          );
        }}
      />
    </WizardContainer>
  );
}
