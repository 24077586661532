/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useLocation, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Login } from './pages/auth/Login';
import { useSelector } from 'react-redux';
import {
  selectIsLoggedIn,
  selectLogout,
  selectUserData,
} from './providers/AuthProvider/slice/selectors';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { ResetPassword } from './pages/auth/ResetPassword';
import { OrganisationSelector } from './providers/OrganisationSelector';
import { Portal } from './pages/Portal';
import { Signup } from './pages/Signup';
import { GlobalStyles } from 'styles/GlobalStyles';
import { ManageAuth } from './pages/ManageAuth';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Logout } from './pages/auth/Logout';
import { VerifyEmail } from './pages/VerifyEmail';
import { getToken } from '../utils/request';
import { LoadingIndicator } from './components/LoadingIndicator';
import { AuthPageContainer } from './components/AuthPageContainer';

const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

declare global {
  interface Window {
    HubSpotConversations: {
      widget: {
        open: () => void;
        close: () => void;
        refresh: () => void;
      };
      // Add more methods and properties as needed
    };
  }
}

export function App() {
  const { i18n } = useTranslation();
  const loggedIn = useSelector(selectIsLoggedIn);
  const { loading: loggingOut } = useSelector(selectLogout);
  const userData = useSelector(selectUserData);
  const [token, setToken] = useState(getToken());
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh();
    }
  }, [location.pathname]);

  const requiredRedirectUrl = useMemo(() => {
    if (!userData) return '';

    switch (userData.registered_status) {
      case 'invited':
        return `/signup/${userData.invite_code}`;
      case 'onboarding':
      case 'new':
        return '/signup';
      default:
        return '';
    }
  }, [userData]);

  // Listen for changes to the token
  useEffect(() => {
    setToken(getToken());
    const handleStorageChange = () => {
      setToken(getToken());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s | Governance360"
        defaultTitle="Welcome"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Governance360 portal" />
      </Helmet>
      <ErrorBoundary
        onError={e => {
          console.log(e);
        }}
        FallbackComponent={({ error, resetErrorBoundary }, context) => (
          <Container maxWidth={'md'} sx={{ py: 8 }}>
            <Stack alignItems={'center'}>
              <Typography variant={'h1'}>Something went wrong</Typography>
              <Button onClick={resetErrorBoundary}>Try again</Button>
            </Stack>
          </Container>
        )}
      >
        <Routes>
          <Route path={'verify-email'} element={<VerifyEmail />} />
          <Route
            path={'logout'}
            element={
              <ProtectedRoute isAccessible={loggedIn} redirectToPath={'/'}>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/signup/*"
            element={
              <Routes>
                <Route path={':code'} element={<Signup />} />
                <Route path={''} element={<Signup />} />
              </Routes>
            }
          />
          <Route
            path={'/auth/manage/*'}
            element={
              <ProtectedRoute isAccessible={loggedIn} redirectToPath={'/'}>
                <ManageAuth />
              </ProtectedRoute>
            }
          />

          <Route
            path="/auth/*"
            element={
              <>
                <ProtectedRoute isAccessible={!loggedIn} redirectToPath={'/'}>
                  {loggingOut ? (
                    <AuthPageContainer>
                      <LoadingIndicator />
                    </AuthPageContainer>
                  ) : (
                    <>
                      <Routes>
                        <Route
                          path={'forgot-password'}
                          element={<ForgotPassword />}
                        />
                        <Route
                          path={'reset-password'}
                          element={<ResetPassword />}
                        />
                        <Route path={'*'} element={<Login />} />
                      </Routes>
                    </>
                  )}
                </ProtectedRoute>
              </>
            }
          />
          <Route
            errorElement={<BubbleError />}
            path={'/*'}
            element={
              <ProtectedRoute
                isAccessible={!requiredRedirectUrl}
                redirectToPath={requiredRedirectUrl}
              >
                <>
                  <Routes>
                    <Route
                      errorElement={<BubbleError />}
                      path="/*"
                      element={
                        <ProtectedRoute
                          isAccessible={loggedIn}
                          redirectToPath={`/auth?redirect=${location.pathname}`}
                        >
                          <OrganisationSelector>
                            <Portal />
                          </OrganisationSelector>
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </>
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
      </ErrorBoundary>
      <GlobalStyles />
    </>
  );
}
