import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RiCheckboxBlankCircleLine, RiRadioButtonFill } from 'react-icons/ri';

interface Props extends ButtonProps {
  label: ReactNode;
  onChange: (e) => void;
  checked: boolean;
  children?: ReactNode;
}

export function RadioButton(props: Props) {
  const { label, checked, onChange, children, sx, ...rProps } = props;
  const theme = useTheme();
  const iconSize = 20;

  return (
    <Button
      fullWidth
      onClick={onChange}
      variant="outlined"
      startIcon={
        <Box sx={{ color: checked ? 'primary.main' : theme.palette.grey[700] }}>
          {checked ? (
            <RiRadioButtonFill fontSize={iconSize} />
          ) : (
            <RiCheckboxBlankCircleLine fontSize={iconSize} />
          )}
        </Box>
      }
      sx={{
        justifyContent: 'start',
        alignItems: 'start',
        textAlign: 'start',
        p: 3,
        gap: 2,
        borderColor: !checked && `${theme.palette['grey'][700]}`,
        backgroundColor: checked ? 'common.beige' : 'white',
        '&:hover': {
          borderColor: !checked && `primary.main`,
          backgroundColor: checked ? 'common.beige' : 'white',
          opacity: !checked && '0.8',
        },
        ...sx,
      }}
      {...rProps}
    >
      {label}
    </Button>
  );
}
