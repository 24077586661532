import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { OpenAndProgress } from './OpenAndProgress';
import { IOrganisation } from 'types/types';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu } from 'app/components/SectionMenu';
import { selectCommittees } from '../Committees/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DashedButton } from 'app/components/DashedButton';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useCommitteesSlice } from '../Committees/slice/hook';
import { useEffectOnce } from 'usehooks-ts';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useLoadCommittees } from 'utils/useLoadCommittees';

interface Props {
  organisation: IOrganisation;
}

export function ActionRegisterSection(props: Props) {
  const { organisation } = props;

  const { reloadCommittees, loadCommittees, hasCommittees } = useLoadCommittees(
    organisation.id,
  );

  useEffectOnce(() => {
    return reloadCommittees();
  });

  const { hasPermission } = useHasPermission();

  const canManageCommittees = hasPermission(
    'manage-committees',
    'organisation',
    organisation.id,
  );

  const location = useLocation();

  return (
    <>
      <Helmet title={'Actions Register'}>
        <meta
          name="description"
          content="Manage your own actions on this page"
        />
      </Helmet>
      <SectionHeader
        title="Actions"
        sectionMenu={
          <>
            {hasCommittees && (
              <SectionMenu
                title={'All'}
                path={'./all'}
                isActive={
                  location.pathname ===
                  `/organisation/${organisation.id}/actions/all`
                }
              />
            )}
          </>
        }
      />
      <Container>
        <Box sx={{ my: 4 }}>
          <Routes>
            {!hasCommittees ? (
              <>
                {/* if it doesn't have committees, display a cta button to admins, otherwise a message to org users */}
                {canManageCommittees ? (
                  <>
                    {!loadCommittees.loading && loadCommittees.touched && (
                      <>
                        <Route
                          path={''}
                          element={
                            <DashedButton
                              title="Get Started with Your Board!"
                              path="../meetings"
                              description={
                                <Stack>
                                  <Typography>
                                    🚀 Your journey begins here. 🚀
                                  </Typography>
                                  <Typography>
                                    It looks like you haven’t set up your Board
                                    yet. Kickstart your collaboration by
                                    creating your first committee. This will
                                    unlock the full potential of your Action
                                    Register and help you manage tasks
                                    effectively.
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    💡 Pro Tip: Once your committee is ready,
                                    you can not only schedule meetings but also
                                    create and assign actions in your
                                    organisation, and track progress with ease.
                                  </Typography>
                                </Stack>
                              }
                              ctaText={'Start Your Board Now'}
                            />
                          }
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Route
                    path={''}
                    element={
                      <DashedButton
                        title="No actions"
                        description={
                          <Typography>You haven't got any actions</Typography>
                        }
                      />
                    }
                  />
                )}
                <Route path={'*'} element={<Navigate to={''} replace />} />
              </>
            ) : (
              <>
                {/* if it has committees, navigates to all actions */}
                <Route
                  path={'all'}
                  element={<OpenAndProgress organisation={organisation} />}
                />
                <Route path={'*'} element={<Navigate to={'all'} replace />} />
              </>
            )}
          </Routes>
        </Box>
      </Container>
    </>
  );
}
