/**
 *
 * AuthSettings
 *
 */
import * as React from 'react';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { RiLockPasswordLine, RiMailLine } from 'react-icons/ri';
import { PasswordField } from 'app/components/PasswordField';
import Yup from 'utils/yup';
import { TextField } from 'formik-mui';
import { Link } from 'react-router-dom';

interface Props {
  saving?: boolean;
  onSave: (payload: { email: string; confirm_email: string }) => void;
  cancelPath: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('This field is required'),
  confirm_email: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('email'), null], 'Emails must match'),
});

export function ChangeEmailForm(props: Props) {
  const { saving, onSave, cancelPath } = props;

  return (
    <Box>
      <Formik<{ email: string; confirm_email: string }>
        validationSchema={schema}
        initialValues={{
          email: '',
          confirm_email: '',
        }}
        onSubmit={values => {
          onSave(values);
        }}
      >
        {formik => {
          const { values, setSubmitting, isSubmitting, dirty, isValid } =
            formik;
          if (isSubmitting && !saving) setSubmitting(false);
          return (
            <Form>
              <Stack>
                <Field
                  label={'New email'}
                  component={TextField}
                  id="email"
                  type={'email'}
                  name="email"
                  placeholder="New email"
                  inputProps={{ autoComplete: 'off' }}
                  fullWidth
                />{' '}
                <Field
                  label={'Confirm new email'}
                  component={TextField}
                  id="confirm_email"
                  type={'email'}
                  name="confirm_email"
                  placeholder="Confirm new email"
                  inputProps={{ autoComplete: 'off' }}
                  fullWidth
                />
              </Stack>
              <Divider />
              <Stack>
                <Button
                  type={'submit'}
                  disabled={!dirty || saving || isSubmitting || !isValid}
                >
                  Save changes
                </Button>
                <Button
                  to={cancelPath}
                  size="small"
                  component={Link}
                  variant={'text'}
                >
                  Back to the application
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
