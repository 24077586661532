import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { isEqual } from 'lodash';

const selectDomain = (state: RootState) => {
  return (state || {}).authProvider || initialState;
};

export const selectAuthProvider = createSelector(
  [selectDomain],
  authProviderState => authProviderState,
);

export const selectUserData = createSelector(
  [selectDomain],
  authProviderState => authProviderState.loadUser.data,
  {
    memoizeOptions: {
      equalityCheck: isEqual, // Deep equality check for memoization
    },
  },
);

export const selectUserPermissions = createSelector(
  [selectUserData],
  user => user!.permissions,
);

export const selectIsAuthRefreshing = createSelector(
  [selectDomain],
  authProviderState => authProviderState.refreshing,
);

export const selectLogin = createSelector(
  [selectDomain],
  authProviderState => authProviderState.login,
);

export const selectAuthenticate2fa = createSelector(
  [selectDomain],
  authProviderState => authProviderState.authenticate2fa,
);

export const selectLogout = createSelector(
  [selectDomain],
  authProviderState => authProviderState.logout,
);

export const selectForgotPassword = createSelector(
  [selectDomain],
  authProviderState => authProviderState.forgotPassword,
);

export const selectResetPassword = createSelector(
  [selectDomain],
  authProviderState => authProviderState.resetPassword,
);

export const selectActivateAccount = createSelector(
  [selectDomain],
  authProviderState => authProviderState.activateAccount,
);

export const selectIsLoggedIn = createSelector(
  [selectDomain],
  authProviderState => authProviderState.loggedIn,
);
