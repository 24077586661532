import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { CommitteesState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { IAbridgedCommittee, ICommittee } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: CommitteesState = {
  loadCommittees: getApiCallInitialState('list'),
  loadCommittee: getApiCallInitialState(),
  deleteCommittee: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'committees',
  initialState,
  reducers: {
    loadCommitteesInit(state, action: PayloadAction<void>) {
      state.loadCommittees = initialState.loadCommittees;
    },
    loadCommitteesRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.loadCommittees.loading = true;
      state.loadCommittees.touched = true;
      state.loadCommittees.error = undefined;
    },
    loadCommitteesSuccess(
      state,
      action: PayloadAction<{ data: IAbridgedCommittee[] }>,
    ) {
      state.loadCommittees.loading = false;
      state.loadCommittees.error = undefined;
      state.loadCommittees.data = action.payload.data;
    },
    loadCommitteesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadCommittees.loading = false;
      state.loadCommittees.error = action.payload;
    },
    loadCommitteeInit(state, action: PayloadAction<void>) {
      state.loadCommittee = initialState.loadCommittee;
    },

    loadCommitteeRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.loadCommittee.loading = true;
      state.loadCommittee.error = undefined;
    },
    loadCommitteeSuccess(state, action: PayloadAction<{ data: ICommittee }>) {
      state.loadCommittee.loading = false;
      state.loadCommittee.error = undefined;
      state.loadCommittee.data = action.payload.data;
    },
    loadCommitteeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadCommittee.loading = false;
      state.loadCommittee.error = action.payload;
    },
    deleteCommitteeRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteCommittee.loading = true;
      state.deleteCommittee.error = undefined;
    },
    deleteCommitteeSuccess(state, action: PayloadAction<{ data: ICommittee }>) {
      state.deleteCommittee.loading = false;
      state.deleteCommittee.error = undefined;
      state.deleteCommittee.data = action.payload.data;
    },
    deleteCommitteeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteCommittee.loading = false;
      state.deleteCommittee.error = action.payload;
    },
  },
});

export const { actions: committeesActions } = slice;
