/**
 *
 * Conflicts
 *
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { ConflictsTable } from 'app/components/ConflictsTable';
import { useConflictsSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectConflicts } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { RiAddCircleFill, RiShareForward2Line } from 'react-icons/ri';
import {
  ConflictFormWidget,
  useConflictFormWidget,
} from 'app/widgets/ConflictFormWidget';
import { IConflict } from 'types/types';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';
import useHandleApiResponse from 'utils/useHandleApiResponse';
import { Link } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';

interface Props {}

export function List(props: Props) {
  const { actions } = useConflictsSlice();
  const dispatch = useDispatch();
  const { loadConflicts, deleteConflict, closeOffConflict } =
    useSelector(selectConflicts);

  const { open, close } = useConflictFormWidget();
  const confirm = useConfirm();

  const apiRef = useGridApiRef();

  const load = () => dispatch(actions.loadConflictsRequest());

  useHandleApiResponse(deleteConflict, 'Declaration removed', {
    errorMessage: 'Unable to remove declaration',
    onSuccess: () => {
      load();
      close();
    },
  });

  useHandleApiResponse(closeOffConflict, 'Declaration closed off', {
    errorMessage: 'Unable to close off declaration',
    onSuccess: () => {
      load();
      close();
    },
  });

  useEffectOnce(() => {
    load();
  });

  const removeConflict = (id: number | string) =>
    confirm({
      title: 'Delete declaration',
      description: 'Are you sure?',
    })
      .then(() => dispatch(actions.deleteConflictRequest({ id: id })))
      .catch(noop);

  return (
    <>
      <Helmet title={'Declarations of Interest'}>
        <meta name="description" content="Declarations of Interest" />
      </Helmet>
      <Stack spacing={6}>
        <Typography variant="h3">Declarations of Interest</Typography>

        <Stack>
          <Typography variant="h4">
            What are Declarations of Interest?
          </Typography>
          <Typography variant="body2">
            This is a complex area and your first port of call should be your
            Organisation's Policy or guidance in this area. In brief though,
            under the Companies Act 2006, company directors who are in any way
            directly or indirectly interested in a proposed transaction must
            declare the nature and extent of that interest to the other
            directors.
          </Typography>
        </Stack>

        <Box>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'start', md: 'center' }}
          >
            <Typography variant="h4">Your Declarations of Interest</Typography>
            <Box>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                <Button
                  startIcon={<RiShareForward2Line />}
                  variant="outlined"
                  disabled={!loadConflicts.data.length}
                  onClick={() => {
                    apiRef.current.exportDataAsCsv({
                      fields: [
                        'description',
                        'date_start',
                        'date_end',
                        'organisation_name',
                      ],
                    });
                  }}
                >
                  Export as CSV
                </Button>
                <Button
                  variant={'outlined'}
                  startIcon={<RiAddCircleFill />}
                  // onClick={() => {
                  //   open({});
                  // }}
                  component={Link}
                  to={'./add'}
                >
                  Add a Declaration of Interest
                </Button>
              </Stack>
            </Box>
            <ConflictFormWidget
              onChange={load}
              onDelete={(conflict: IConflict) => removeConflict(conflict.id)}
            />
          </Stack>
        </Box>
        <ConflictsTable
          apiRef={apiRef}
          context={'profile'}
          conflicts={loadConflicts.data}
          editable
          onEdit={id => open({ id })}
          onRemove={id => removeConflict(id)}
          onCloseOff={id =>
            confirm({
              title: 'Close off conflict',
              description: 'Are you sure?',
            })
              .then(() => dispatch(actions.closeOffConflictRequest({ id })))
              .catch(noop)
          }
        />
        <Box>
          <Stack spacing={2}>
            <Typography variant="h4">
              Can I delete a Declaration of Interest?
            </Typography>
            <Typography variant="body2">
              Whilst the ultimate record will be your own Organisation's
              Statutory Minute book (which should include the Declaration
              Register), on Governance360 we suggest that Administrators archive
              any Declarations that have 'fallen away' (for example, shares you
              once held in a competitor have now been sold). This is both to
              acknowledge that you declared the matter properly at the time, and
              also retains the fact on record as part of your Organisation's
              single source of truth. The choice of course, of what you actually
              choose to do as an Organisation is yours!
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
