import { Paper, PaperProps } from '@mui/material';
import React from 'react';

interface Props extends PaperProps {}

export function WizardContainer(props: Props) {
  const { sx, ...rProps } = props;
  return (
    <Paper
      square
      variant="outlined"
      sx={{
        backgroundColor: 'grey.200',
        my: 6,
        px: 4,
        py: 6,
        overflow: 'hidden',
        border: 'none',
        ...sx,
      }}
      {...rProps}
    >
      {props.children}
    </Paper>
  );
}
