import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, LoginResult } from './types';
import { apiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authProviderSaga } from './saga';
import { loadState } from 'store/localStorage';

// The initial state of the AuthProvider container
export const initialState: ContainerState = {
  login: {
    ...apiCallInitialState,
    data: {
      validation_token: undefined,
      token: undefined,
      method: undefined,
      contact: undefined,
    },
  },
  authenticate2fa: { ...apiCallInitialState, data: { attempts: 0 } },
  loadUser: apiCallInitialState,
  logout: apiCallInitialState,
  forgotPassword: apiCallInitialState,
  resetPassword: apiCallInitialState,
  activateAccount: apiCallInitialState,
  initialised: false,
  authenticated: false,
  loggedIn: false,
  refreshing: false,
  loading: false,
  resendCode: apiCallInitialState,
  sendEmailVerification: apiCallInitialState,
  verifyEmail: apiCallInitialState,
  ...(loadState().authProvider || {}),
};

export const slice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {
    setInitialised(state, action: PayloadAction<boolean>) {
      state.initialised = action.payload;
    },
    userTokenRefreshRequest(state, action: PayloadAction<void>) {
      state.refreshing = true;
    },
    userTokenRefreshSuccess(state, action: PayloadAction<void>) {
      state.refreshing = false;
    },
    userTokenRefreshFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.refreshing = false;
    },
    userLoginInit(state, action: PayloadAction<void>) {
      state.login = initialState.login;
    },
    userLoginRequest(state, action: PayloadAction<any>) {
      state.login.loading = true;
      state.login.error = undefined;
    },
    userLoginSuccess(state, action: PayloadAction<{ data: LoginResult }>) {
      state.login.loading = false;
      state.login.error = undefined;
      state.login.data = action.payload.data;
      if (action.payload.data.validation_token) {
        state.loggedIn = false;
      } else {
        state.loggedIn = true;
        state.authenticate2fa = initialState.authenticate2fa;
        state.login = initialState.login;
      }
    },
    userLoginFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.login.loading = false;
      state.login.error = action.payload || { message: 'Unknown error' };
    },

    resendCodeRequest(
      state,
      action: PayloadAction<{
        validation_token: string;
      }>,
    ) {
      state.resendCode.loading = true;
      state.resendCode.error = undefined;
    },
    resendCodeSuccess(state, action: PayloadAction) {
      state.resendCode.loading = false;
      state.resendCode.error = undefined;
      state.resendCode.data = true;
    },
    resendCodeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.resendCode.loading = false;
      state.resendCode.error = action.payload;
    },
    userAuthenticate2faRequest(state, action: PayloadAction<any>) {
      state.authenticate2fa.loading = true;
      state.authenticate2fa.error = undefined;
    },
    userAuthenticateSuccess(state, action: PayloadAction<void>) {
      state.authenticated = true;
      state.authenticate2fa = initialState.authenticate2fa;
    },
    userComplete2fa(state) {
      state.loggedIn = true;
      state.login = initialState.login;
      state.authenticate2fa = initialState.authenticate2fa;
    },
    userAuthenticate2faFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.authenticate2fa.loading = false;
      state.authenticate2fa.error = action.payload || {
        message: 'Unknown error',
      };
      if (state.authenticate2fa.data.attempts < 3) {
        state.authenticate2fa.data.attempts =
          state.authenticate2fa.data.attempts + 1;
      } else {
        state.login = initialState.login;
        state.authenticate2fa = initialState.authenticate2fa;
      }
    },

    autoLoginRequest(state, action: PayloadAction<{ key: string }>) {
      state.login.loading = true;
      state.login.error = undefined;
    },
    autoLoginSuccess(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.login.loading = false;
      state.login.error = undefined;
    },
    autoLoginFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.login.loading = false;
      state.login.error = action.payload;
    },
    userLogoutRequest(state, action: PayloadAction<void>) {
      state.logout.loading = true;
      state.logout.error = undefined;
      state.loadUser.data = undefined;
      state.loggedIn = false;
      state.authenticated = false;
    },
    userLogoutSuccess(state, action: PayloadAction<void>) {
      state.logout.loading = false;
      state.logout.error = undefined;
      state.loadUser.data = undefined;
      state.loggedIn = false;
      state.authenticated = false;
    },
    userLogoutFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loggedIn = false;
      state.authenticated = false;
      state.logout.loading = false;
      state.logout.error = action.payload;
      state.loadUser.data = undefined;
    },
    loadUserDataRequest(state, action: PayloadAction<void>) {
      state.loadUser.loading = true;
      state.loadUser.error = undefined;
    },
    loadUserDataSuccess(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.initialised = true;
      state.loadUser.loading = false;
      state.loadUser.touched = true;
      state.loadUser.data = action.payload.data;
      state.loadUser.error = undefined;
    },
    loadUserDataFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadUser.loading = false;
      state.initialised = true;
      state.loadUser.touched = true;
      state.loadUser.error = action.payload;
    },
    forgotPasswordRequest(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = true;
      state.forgotPassword.error = undefined;
    },
    forgotPasswordSuccess(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = false;
      state.forgotPassword.data = action.payload;
      state.forgotPassword.error = undefined;
    },
    forgotPasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.forgotPassword.error = action.payload;
      state.forgotPassword.loading = false;
    },
    resetPasswordRequest(state, action: PayloadAction<any>) {
      state.resetPassword.loading = true;
      state.resetPassword.error = undefined;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.resetPassword.loading = false;
      state.resetPassword.data = action.payload;
      state.resetPassword.error = undefined;
    },
    resetPasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.resetPassword.error = action.payload;
      state.resetPassword.loading = false;
    },
    activateAccountRequest(
      state,
      action: PayloadAction<{ activation_code: string }>,
    ) {
      state.activateAccount.loading = true;
      state.activateAccount.error = undefined;
    },
    activateAccountSuccess(state, action: PayloadAction<any>) {
      state.activateAccount.loading = false;
      state.activateAccount.data = action.payload;
      state.activateAccount.error = undefined;
    },
    activateAccountFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.activateAccount.error = action.payload;
      state.activateAccount.loading = false;
    },
    sendEmailVerificationRequest(state, action: PayloadAction<void>) {
      state.sendEmailVerification.loading = true;
      state.sendEmailVerification.error = undefined;
    },
    sendEmailVerificationSuccess(state, action: PayloadAction<any>) {
      state.sendEmailVerification.loading = false;
      state.sendEmailVerification.data = action.payload;
      state.sendEmailVerification.error = undefined;
    },
    sendEmailVerificationFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.sendEmailVerification.error = action.payload;
      state.sendEmailVerification.loading = false;
    },
    verifyEmailRequest(
      state,
      action: PayloadAction<{ user_id: number; token: string }>,
    ) {
      state.verifyEmail.loading = true;
      state.verifyEmail.error = undefined;
    },
    verifyEmailSuccess(state, action: PayloadAction<any>) {
      state.verifyEmail.loading = false;
      state.verifyEmail.data = action.payload;
      state.verifyEmail.error = undefined;
    },
    verifyEmailFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.verifyEmail.error = action.payload;
      state.verifyEmail.loading = false;
    },
  },
});

export const { actions: authProviderActions } = slice;
