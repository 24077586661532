/**
 *
 * PersonalDetailsForm
 *
 */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { RiAddCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { IUser } from 'types/types';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';
import { TitledField } from 'app/components/TitledField';
import { TelephoneField } from '../../components/TelephoneField';
import Yup from 'utils/yup';

interface Props {
  user: IUser;
  onSave: (payload: Partial<IUser>) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
});

export function PersonalDetailsForm(props: Props) {
  const { user, onSave, saving } = props;

  const initialValues = {
    id: undefined,
    title: '',
    first_name: '',
    last_name: '',
    dob: '',
    email: '',
    ...user,
  } as Partial<IUser>;

  const [addedHomeField, setAddedHomeField] = useState(
    !!initialValues.telephone_home,
  );
  const [addedWorkField, setAddedWorkField] = useState(
    !!initialValues.telephone_work,
  );

  return (
    <Formik<Partial<IUser>>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const {
          setSubmitting,
          isSubmitting,
          dirty,
          values,
          setFieldValue,
          setValues,
        } = formik;
        if (isSubmitting && !saving) setSubmitting(false);

        return (
          <Form>
            <Box>
              <Box mb={5}>
                <Stack>
                  <Typography>
                    Here you can edit your personal information. These details
                    will be visible by other board members throughout
                    Governance360.
                  </Typography>
                </Stack>
              </Box>

              <Stack spacing={3} sx={{ my: 3 }}>
                <Box>
                  <Stack spacing={4}>
                    <TitledField title="Name">
                      <Box>
                        <Grid container spacing={1} alignItems={'center'}>
                          <Grid
                            item
                            xs={4}
                            md={2}
                            lg={1}
                            sx={{ mb: { xs: 2, md: 0 } }}
                          >
                            <Field
                              component={TextField}
                              id="title"
                              name="title"
                              label="Title"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            md={5}
                            lg={5.5}
                            sx={{ mb: { xs: 2, md: 0 } }}
                          >
                            <Field
                              component={TextField}
                              id="first_name"
                              name="first_name"
                              label="First name"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={5} lg={5.5}>
                            <Field
                              component={TextField}
                              id="last_name"
                              name="last_name"
                              label="Last name"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TitledField>

                    <Box>
                      <Grid container>
                        <Grid item xs={4}>
                          <TitledField title="Date of birth">
                            <DateTimePickerWrapper
                              name="dob"
                              label="D.O.B."
                              format={'DD/MM/YYYY'}
                              sx={{ width: '100%' }}
                            />
                          </TitledField>
                        </Grid>
                        <Grid item xs={8} md={4}>
                          <TitledField title="Occupation">
                            <Field
                              component={TextField}
                              id="occupation"
                              name="occupation"
                              placeholder={'Occupation'}
                              fullWidth
                            />
                          </TitledField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TitledField title="Nationality">
                            <Field
                              component={TextField}
                              id="nationality"
                              name="nationality"
                              placeholder="Nationality"
                              fullWidth
                            />
                          </TitledField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Divider />
            <Box>
              <Box mb={5}>
                <Typography variant={'h3'}>Contact details</Typography>
              </Box>

              <Stack spacing={3} sx={{ my: 3 }}>
                <Box>
                  <Grid container>
                    {/* address */}
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <TitledField title="Address">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                id="address1"
                                name="address1"
                                label="Address Line 1"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                id="address2"
                                name="address2"
                                label="Address Line 2"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={TextField}
                                id="city"
                                name="city"
                                label="Town/city"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={TextField}
                                id="county"
                                name="county"
                                label="County"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={TextField}
                                id="country"
                                name="country"
                                label="Country"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={TextField}
                                id="postcode"
                                name="postcode"
                                label="Postcode"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </TitledField>
                      </Box>
                    </Grid>
                    {/* telephone */}
                    <Grid item xs={12} sm={6}>
                      <TitledField title="Telephone">
                        <Stack>
                          <Box>
                            <Grid container spacing={2} alignItems={'center'}>
                              <Grid item xs={12}>
                                <Stack direction={'row'}>
                                  <TelephoneField
                                    onChange={value => {
                                      setFieldValue('telephone_mobile', value);
                                    }}
                                    value={values.telephone_mobile}
                                    label={'Mobile'}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                          {(!!values.telephone_home || addedHomeField) && (
                            <Box>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid item xs={11}>
                                  <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <Stack direction={'row'} width={'100%'}>
                                      <TelephoneField
                                        onChange={value => {
                                          setFieldValue(
                                            'telephone_home',
                                            value,
                                          );
                                        }}
                                        value={values.telephone_home}
                                        label={'Home'}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Tooltip title="Remove home number">
                                      <IconButton
                                        edge="start"
                                        onClick={() => {
                                          setFieldValue('telephone_home', '');
                                          setAddedHomeField(false);
                                        }}
                                        color={'primary'}
                                      >
                                        <RiCloseCircleFill />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                          {(!!values.telephone_work || addedWorkField) && (
                            <Box>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid item xs={11}>
                                  <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <Stack direction={'row'} width={'100%'}>
                                      <TelephoneField
                                        onChange={value => {
                                          setFieldValue(
                                            'telephone_work',
                                            value,
                                          );
                                        }}
                                        value={values.telephone_work}
                                        label={'Work'}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Tooltip title="Remove work number">
                                      <IconButton
                                        edge="start"
                                        onClick={() => {
                                          setFieldValue('telephone_work', '');
                                          setAddedWorkField(false);
                                        }}
                                        color={'primary'}
                                      >
                                        <RiCloseCircleFill />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Stack>
                      </TitledField>
                      <Box sx={{ mt: 2 }}>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          alignItems={{ xs: 'start', md: 'center' }}
                        >
                          {!addedHomeField && !values.telephone_home && (
                            <Button
                              variant="text"
                              startIcon={<RiAddCircleFill />}
                              onClick={() => setAddedHomeField(true)}
                            >
                              Add home number
                            </Button>
                          )}
                          {!addedWorkField && !values.telephone_work && (
                            <Button
                              variant="text"
                              startIcon={<RiAddCircleFill />}
                              onClick={() => setAddedWorkField(true)}
                            >
                              Add work number
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Box>

            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button
                variant={'outlined'}
                type={'button'}
                onClick={() => {
                  setValues({ ...initialValues });
                  setAddedHomeField(!!initialValues.telephone_home);
                  setAddedWorkField(!!initialValues.telephone_work);
                }}
                disabled={!dirty || saving || isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type={'submit'}
                disabled={!dirty || saving || isSubmitting}
              >
                Save changes
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
