import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { BeforeYouBegin } from './BeforeYouBegin';
import { SummaryOfInterest } from './SummaryOfInterest';
import { Confirm } from './Confirm';
import key from 'weak-key';
import { Link } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import moment from 'moment-timezone';
import { IConflict, OrganisationPicker } from 'types/types';

interface Props {
  conflict?: IConflict | undefined;
  onSave: (payload: Partial<IConflict>) => void;
  saving?: boolean;
  organisations: OrganisationPicker[];
}

const schema = [
  {
    //agreement1: Yup.boolean().oneOf([true], 'This field is required'),
    //agreement2: Yup.boolean().oneOf([true], 'This field is required'),
  },
  {
    description: Yup.string().required('This field is required'),
    organisation_ids: Yup.array()
      .of(Yup.number())
      .when('id', {
        is: v => !v,
        then: Yup.array()
          .of(Yup.number())
          .required('At least one selection is required')
          .min(1, 'At least one selection is required'),
      }),
    date_start: Yup.date()
      .typeError('Start date is required')
      .required('Start date is required'),
  },
];

export function Form(props: Props) {
  const { conflict, onSave, saving, organisations } = props;

  const [step, setStep] = useState<number>(0);

  const steps = [
    {
      label: 'Before you begin',
      component: <BeforeYouBegin proceed={() => setStep(1)} />,
    },
    {
      label: 'Summary of Interest',
      component: (
        <SummaryOfInterest
          previous={() => setStep(0)}
          proceed={() => setStep(2)}
          organisations={organisations}
        />
      ),
    },
    {
      label: 'Confirm',
      component: (
        <Confirm previous={() => setStep(1)} organisations={organisations} />
      ),
    },
  ];

  const formikRef = useRef(null);

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [step]);

  const today = useMemo(() => {
    return moment();
  }, []);

  const indexTab = steps.findIndex((tab, index) => index === step);
  const currentValidationSchema = Yup.object().shape(
    Object.assign({}, ...schema.slice(0, indexTab + 1)),
  );
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Formik<
      Partial<IConflict> & {
        organisation_ids: (string | number)[];
      }
    >
      innerRef={formikRef}
      enableReinitialize
      validateOnChange
      validateOnMount
      validationSchema={currentValidationSchema}
      initialValues={
        conflict
          ? {
              ...conflict,
              organisation_ids: [],
            }
          : ({
              id: undefined,
              description: '',
              date_start: today,
              organisation_ids: [],
            } as Pick<IConflict, 'id' | 'description'> & {
              organisation_ids: Array<number | string>;
            })
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          date_start: moment
            .utc(values.date_start)
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }}
    >
      {formik => {
        const { setSubmitting, values, isSubmitting, errors, dirty } = formik;
        if (isSubmitting) setSubmitting(false);

        return (
          <FormikForm>
            <Box>
              <Stack spacing={6}>
                <Typography variant="h3">
                  Add a Declaration of Interest
                </Typography>
                {isMd && (
                  <Stepper activeStep={indexTab}>
                    {steps.map((s, i) => (
                      <Step key={key(s)}>
                        <StepLabel>{s.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
                <React.Fragment>{steps[step].component}</React.Fragment>
                <Button
                  variant="text"
                  component={Link}
                  to="../"
                  sx={{ justifyContent: 'start' }}
                >
                  Cancel this and return to your Declarations of Interest list
                </Button>
              </Stack>
            </Box>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
