import React from 'react';
import { IOrganisation } from 'types/types';

import { MeetingsCardList } from '../MeetingsCardList';

interface Props {
  organisation: IOrganisation;
}

export function Past({ organisation }: Props) {
  return <MeetingsCardList organisation={organisation} context="past" />;
}
