import * as React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { StartBoardValues } from './Wizard';

interface Props {
  next: () => void;
}

export function BoardInfo(props: Props) {
  const { next } = props;
  const { isValid } = useFormikContext<StartBoardValues>();

  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>Board info</Typography>
      <Box>
        <Stack spacing={4}>
          <Typography component={'p'}>
            Boards by Governance360 structures your organisation into
            committees, which are themselves made up of members. Your first
            committee is (typically) called your Board, hence we normally start
            by creating your Statutory Board. Whilst you can have more than one
            board, start by naming your first Board.
          </Typography>
        </Stack>
      </Box>
      <Stack spacing={2}>
        <Typography variant={'h2'}>Name your board committee</Typography>
        <Field
          component={TextField}
          name={'committee_name'}
          placeholder={'Statutory Board'}
        />
      </Stack>

      <Box>
        <Grid container justifyContent={'end'}>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={() => next()} disabled={!isValid}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
