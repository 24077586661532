import React, { ReactNode } from 'react';
import { Box, Paper, PaperProps, Stack, Typography } from '@mui/material';

interface Props extends PaperProps {
  icon?: ReactNode;
  title: string;
  menu?: ReactNode;
  children: ReactNode;
}

export function DashboardCard(props: Props) {
  const { icon, title, menu, children, sx, ...rProps } = props;

  return (
    <Paper variant="outlined" square sx={{ p: 3, ...sx }} {...rProps}>
      <Stack spacing={4}>
        <Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {icon && <Box>{icon}</Box>}
                <Typography variant={'h5'}>{title}</Typography>
              </Stack>
            </Box>
            {menu}
          </Stack>
        </Box>
        <>{children}</>
      </Stack>
    </Paper>
  );
}
