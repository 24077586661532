import {
  Box,
  Button,
  ButtonProps,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ExtendedButtonProps extends ButtonProps {
  component?: React.ElementType;
}

interface Props {
  title: string;
  image: string;
  body: ReactNode;
  subtitle?: ReactNode;
  cta: ReactNode;
  buttonProps?: Omit<ExtendedButtonProps, 'to'> & Partial<LinkProps>;
}

export function CardBody(props: Props) {
  const {
    title,
    image,
    body,
    subtitle,
    cta,
    buttonProps: { sx, ...buttonProps } = {},
  } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            <Box>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h2" color={'primary.main'}>
                    {title}
                  </Typography>
                </Box>

                {isMd && (
                  <CardMedia
                    component={'img'}
                    src={image}
                    alt=""
                    sx={{ borderRadius: 1 }}
                  />
                )}
                {!!subtitle && <Box>{subtitle}</Box>}
                <>{body}</>
              </Stack>
            </Box>
          </Grid>
          {!isMd && (
            <Grid item xs={12} md={6} lg={5}>
              <Box>
                <CardMedia
                  component={'img'}
                  src={image}
                  alt=""
                  sx={{ borderRadius: 1 }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        {cta && (
          <Button variant="outlined" fullWidth sx={{ ...sx }} {...buttonProps}>
            {cta}
          </Button>
        )}
      </Box>
    </>
  );
}
