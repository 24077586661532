/**
 *
 * InvitationForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiCheckFill,
  RiCloseLine,
  RiFilePaper2Line,
  RiGroupLine,
  RiMailLine,
  RiUserFill,
  RiUserLine,
} from 'react-icons/ri';

import { TitledField } from 'app/components/TitledField';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { ICommittee, IInvitation, IRole } from 'types/types';
import * as Yup from 'yup';
import key from 'weak-key';
import { FormikMultiSelectCheckboxes } from 'app/components/FormikMultiSelectCheckboxes';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  onClose: () => void;
  invitation: IInvitation | undefined;
  roles: IRole[];
  committees: ICommittee[];
  onSave: (payload: Partial<IInvitation>) => void;
  onDelete?: (payload: IInvitation) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .when('id', {
      is: v => !v,
      then: Yup.string()
        .email('Must be a valid email')
        .required('This field is required'),
    }),
  committees: Yup.array()
    .of(Yup.number())
    .when('id', {
      is: v => !v,
      then: Yup.array()
        .of(Yup.number())
        .required('At least one committee is required'),
    }),
  first_name: Yup.string().when('id', {
    is: v => !v,
    then: Yup.string().required('This field is required'),
  }),
  last_name: Yup.string().when('id', {
    is: v => !v,
    then: Yup.string().required('This field is required'),
  }),
  job_title: Yup.string().required('This field is required'),
  role_code: Yup.string().required('This field is required'),
});

export function InvitationForm(props: Props) {
  const { invitation, roles, committees, onClose, onSave, onDelete, saving } =
    props;

  return (
    <Formik
      validationSchema={schema}
      initialValues={
        (invitation || {
          id: undefined,
          email: '',
          first_name: '',
          last_name: '',
          job_title: '',
          status: 'active',
          role_code: 'organisation-user',
          committees: committees.filter(c => c.is_default).map(c => c.id),
        }) as Partial<IInvitation> & {
          email?: string;
          committees?: Array<number | string>;
        }
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const { setSubmitting, values, errors, isSubmitting } = formik;
        // @ts-ignore
        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {invitation ? 'Edit / resend invitation' : 'Invite member'}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant={'h3'}>Invitation details</Typography>
                    <RequiredFieldIndicator />
                  </Box>
                  <TitledField width={3} title={'Name *'}>
                    <Grid container>
                      <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
                        <Field
                          component={TextField}
                          id="first_name"
                          type="text"
                          name="first_name"
                          label="First name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="last_name"
                          type="text"
                          name="last_name"
                          label="Last name"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </TitledField>
                  <TitledField width={3} title={'Email *'}>
                    <Field
                      component={TextField}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      fullWidth
                    />
                  </TitledField>

                  <TitledField width={3} title={'Job title *'}>
                    <Field
                      component={TextField}
                      id="job_title"
                      name="job_title"
                      placeholder="Job title"
                      fullWidth
                    />
                  </TitledField>

                  <TitledField width={3} title={'Invitation role *'}>
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      id="role_code"
                      name="role_code"
                      placeholder="Invitation role"
                      fullWidth
                    >
                      {roles.map(c => (
                        <MenuItem key={key(c)} value={c.code}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </TitledField>
                  {!!committees.length && (
                    <TitledField width={3} title={'Committees'}>
                      <Field
                        component={FormikMultiSelectCheckboxes}
                        multiple
                        formControl={{ fullWidth: true }}
                        id="committees"
                        name="committees"
                        placeholder="Committees"
                        fullWidth
                        options={committees.map(c => ({ ...c, label: c.name }))}
                      />
                    </TitledField>
                  )}
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions>
              <CancelAndSaveButtons
                disabled={saving || isSubmitting}
                saveIcon={<RiCheckFill />}
                onCancel={onClose}
                saveText="Resend"
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
