import * as React from 'react';
import { useRef, useMemo, useState } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { RiLockLine, RiMailLine, RiUserLine } from 'react-icons/ri';
import { TextField } from 'formik-mui';

import { PasswordField } from 'app/components/PasswordField';
import { IInvitation } from 'types/types';
import Yup from 'utils/yup';
import { apiRequest } from 'utils/request';
import { Link } from 'react-router-dom';
import { PasswordCriteriaInfo } from 'app/components/PasswordCriteriaInfo';
import { TelephoneField } from 'app/components/TelephoneField';
import { TitledField } from 'app/components/TitledField';

interface Props {
  onSubmit: (values) => void;
  saving?: boolean;
  invitation?: IInvitation | undefined;
}

const emailRegisteredStr = 'This email is already registered';

const schema = Yup.object().shape({
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('This field is required')
    .test('checkEmailUnique', emailRegisteredStr, value => {
      return apiRequest({
        url: 'signup/check-email',
        method: 'post',
        data: {
          email: value,
        },
      })
        .then(res => !!res.data.unique)
        .catch(e => false);
    }),
  password: Yup.string().required('This field is required').checkPassword(),
  password_confirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export function SignupForm(props: Props) {
  const { onSubmit, saving, invitation } = props;
  const formRef = useRef<FormikProps<any>>(null);

  return (
    <Box>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        initialValues={{
          first_name: invitation ? invitation.first_name : '',
          last_name: invitation ? invitation.last_name : '',
          telephone_mobile: '',
          email: invitation ? invitation.email : '',
          password: '',
          password_confirm: '',
        }}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit({
            ...values,
          });
        }}
      >
        {formik => {
          const {
            setSubmitting,
            isSubmitting,
            dirty,
            errors,
            values,
            isValidating,
            setFieldValue,
          } = formik;
          if (isSubmitting && !saving) setSubmitting(false);

          const showLoginLink =
            ((errors.email as string) || '').indexOf(emailRegisteredStr) > -1;
          return (
            <>
              <Form>
                <Box>
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <Typography
                      component={'div'}
                      sx={{
                        '& > *:not(last-child)': {
                          mb: 2,
                        },
                      }}
                    >
                      {invitation ? (
                        <>
                          <p>
                            Please fill in the details below to set-up a new
                            account with Governance360.
                          </p>
                          <p>
                            Your invitation to join{' '}
                            <b>{invitation.organisation_name}</b> as a member
                            will be complete.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Fill in the details below to set-up a new account
                            with Governance360. Creating an account will NOT let
                            you join an existing organisation that uses
                            Governance360. Either contact your organisation’s
                            Administrator to ask for your invite. Or for general
                            help click{' '}
                            <a
                              href="https://knowledge.governance360.com/"
                              target="_new"
                            >
                              here
                            </a>
                            .
                          </p>
                        </>
                      )}
                    </Typography>
                    <Typography variant={'h3'}>Names</Typography>
                    <TitledField width={3} direction="row" title={'Name'}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sx={{ mb: { xs: 2, md: 0 } }}>
                          <Field
                            component={TextField}
                            id="first_name"
                            name="first_name"
                            label="First name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            id="last_name"
                            name="last_name"
                            label="Last name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </TitledField>
                  </Stack>
                </Box>
                <Divider />
                <Box sx={{ my: 4 }}>
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <Typography variant={'h3'}>Email</Typography>
                    {!invitation && (
                      <Typography variant={'body1'}>
                        Your e-mail becomes your User ID – please choose wisely!
                      </Typography>
                    )}
                    <TitledField width={3} direction="row" title={'Email'}>
                      <Stack spacing={1}>
                        <Field
                          inputProps={{ readOnly: !!invitation }}
                          component={TextField}
                          id="email"
                          name="email"
                          placeholder="Email"
                          fullWidth
                        />
                        {showLoginLink && (
                          <Typography
                            component={Link}
                            to={`/auth?redirect=/signup/${
                              invitation ? invitation.code : ''
                            }`}
                          >
                            You can log in here
                          </Typography>
                        )}
                      </Stack>
                    </TitledField>
                  </Stack>
                </Box>
                <Divider />
                <Box sx={{ my: 4 }}>
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <Typography variant={'h3'}>Phone number</Typography>
                    <Typography variant={'body1'}>
                      Please enter a contact number should any additional
                      verification be required
                    </Typography>
                    <TitledField
                      width={3}
                      direction="row"
                      title={'Phone number'}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack direction={'row'} spacing={2}>
                            <TelephoneField
                              value={values.telephone_mobile}
                              onChange={e =>
                                setFieldValue('telephone_mobile', e)
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </TitledField>
                  </Stack>
                </Box>
                <Divider />

                <Box sx={{ my: 4 }}>
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <Typography variant={'h3'}>Password</Typography>
                    <Typography>
                      Choose a password. You can easily change or reset this
                      later.
                    </Typography>
                    <PasswordCriteriaInfo />

                    <TitledField width={3} direction="row" title={'Password'}>
                      <Stack>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ mb: { xs: 2, md: 0 } }}
                          >
                            <Field
                              component={PasswordField}
                              id="password"
                              name="password"
                              label="Password"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={PasswordField}
                              id="password_confirm"
                              name="password_confirm"
                              label="Confirm Password"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </TitledField>
                  </Stack>
                </Box>

                <Divider />

                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <Button
                    type={'submit'}
                    disabled={!dirty || saving || isSubmitting || isValidating}
                  >
                    Sign up
                  </Button>
                </Stack>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
