/**
 *
 * OrganisationDetails
 *
 */
import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { RiBuilding2Line, RiHome5Line } from 'react-icons/ri';
import { IAbridgedOrganisation } from 'types/types';
import { TitledField } from 'app/components/TitledField';

interface Props {
  organisation: IAbridgedOrganisation;
}

export function OrganisationDetails(props: Props) {
  const { organisation } = props;

  return (
    <Box>
      <Stack spacing={3}>
        <Typography>
          Basic information about the Organisation you are a member of.
        </Typography>

        <TitledField title={'Name'}>
          <Typography>{organisation.name}</Typography>
        </TitledField>

        <TitledField title={'Address'}>
          <Typography>
            {[
              organisation.premises,
              organisation.address_line_1,
              organisation.address_line_2,
              organisation.locality,
              organisation.region,
              organisation.country,
              organisation.postal_code,
            ]
              .filter(a => !!a)
              .join(', ')}
          </Typography>
        </TitledField>
      </Stack>
    </Box>
  );
}
