import { Box, Button, Stack, Typography } from '@mui/material';
import { PhoneAuthIcon } from 'app/components/assets/PhoneAuthIcon';
import React from 'react';
import {
  RiLockLine,
  RiLockUnlockLine,
  RiInformationLine,
  RiMessage2Fill,
  RiMailLine,
  RiAlertLine,
} from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { Link } from 'react-router-dom';
import { hideEmail, hidePhoneNo } from 'utils/hideChars';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

interface Props {}

export function SecurityAndPasswords(props: Props) {
  const { loadUser } = useSelector(selectAuthProvider);
  if (!loadUser.data) return <LoadingIndicator />;
  const lastPasswordReset = loadUser.data?.days_since_last_password_reset;

  const { mfa_method, email, telephone_mobile } = loadUser.data || {};
  const mfaMethodIcon = () => {
    switch (mfa_method) {
      case 'auth-app':
        return <PhoneAuthIcon color="currentColor" width="20" height="20" />;
      case 'sms':
        return <RiMessage2Fill fontSize={20} />;
      case 'email':
        return <RiMailLine fontSize={20} />;
    }
  };

  const lastTimeChanged = () => {
    const baselineText = 'You last set your password ';
    switch (lastPasswordReset) {
      case 0:
        return `${baselineText} today`;
      case 1:
        return `${baselineText} yesterday`;
      default:
        return `${baselineText} ${lastPasswordReset} days ago`;
    }
  };

  const getMethod = () => {
    switch (mfa_method) {
      case 'sms':
        return `SMS/text message to ${hidePhoneNo(telephone_mobile)}`;
      case 'email':
        return `email to ${hideEmail(email)}`;
      case 'auth-app':
        return 'Authenticator app';
      default:
        return '';
    }
  };

  return (
    <>
      <Typography variant="h3">Security & passwords</Typography>
      <Stack spacing={6}>
        <Stack>
          <Typography variant="h5">Keeping your account secure</Typography>
          <Typography>
            Take control of your account safety with these options.
          </Typography>
        </Stack>

        <Stack alignItems={'flex-start'}>
          <Typography variant="h5">Password</Typography>
          <Typography>
            We recommend that you change your password every 60 days.
          </Typography>
          {lastPasswordReset >= 0 && (
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              color={lastPasswordReset > 60 ? 'warning.main' : 'success.main'}
            >
              {lastPasswordReset > 60 ? <RiAlertLine /> : <RiInformationLine />}
              <Typography>{lastTimeChanged()}</Typography>
            </Stack>
          )}
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: 1 }}
            component={Link}
            to={'/auth/manage/change-password'}
          >
            Change Password
          </Button>
        </Stack>
        <Stack alignItems={'flex-start'}>
          <Typography variant="h5">Email address</Typography>
          <Typography>
            You are logged in as {hideEmail(email)}. You can change your email
            address here.
          </Typography>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: 1 }}
            component={Link}
            to={'/auth/manage/change-email'}
          >
            Change Email Address
          </Button>
        </Stack>
        <Stack alignItems={'flex-start'}>
          <Typography variant="h5">2-factor authentication (2FA)</Typography>
          <Typography>
            Enable 2FA to add an extra layer of protection to your account. You
            can manage 2FA via email, phone or the app authenticator.
          </Typography>
          <Box>
            <Button
              startIcon={!!mfa_method ? <RiLockLine /> : <RiLockUnlockLine />}
              component={Link}
              to={'/auth/manage/setup'}
              color={!!mfa_method ? 'success' : 'error'}
              variant="text"
              sx={{ p: 0, mt: 1 }}
            >
              2 factor authentication is {!!mfa_method ? 'on' : 'off'}
            </Button>
          </Box>
          <Box>
            {!!mfa_method ? (
              <Box>
                <Stack direction={'row'}>
                  <Button
                    startIcon={mfaMethodIcon()}
                    component={Link}
                    to={'/auth/manage/setup'}
                    sx={{ p: 0, color: 'common.black', fontWeight: 400 }}
                    variant="text"
                  >
                    2FA enabled via {getMethod()}
                  </Button>
                  <Button
                    component={Link}
                    variant="text"
                    to={'/auth/manage/get-codes'}
                  >
                    Get backup codes
                  </Button>
                </Stack>
                <Button
                  size="small"
                  component={Link}
                  to={'/auth/manage/setup'}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                >
                  Change or remove 2 factor authentication
                </Button>
              </Box>
            ) : (
              <Button
                component={Link}
                to={'/auth/manage/setup'}
                size="small"
                variant="outlined"
                sx={{ mt: 1 }}
              >
                Set up 2-factor authentication now
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
