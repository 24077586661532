import { takeLatest } from 'redux-saga/effects';
import { meetingsActions as actions } from '.';
import { createRequestSaga, createUploadRequestSaga } from 'utils/saga';

const loadMeetings = createRequestSaga({
  actionType: actions.loadMeetingsRequest.type,
  url: `meetings`,
  data: p => ({ ...p, 'page[size]': 500 }),
  successAction: actions.loadMeetingsSuccess,
  failureAction: actions.loadMeetingsFailed,
});

const loadMeeting = createRequestSaga({
  actionType: actions.loadMeetingRequest.type,
  url: p => `meetings/${p.id}`,
  successAction: actions.loadMeetingSuccess,
  failureAction: actions.loadMeetingFailed,
});

const updateMeetingSettings = createRequestSaga({
  actionType: actions.updateMeetingSettingsRequest.type,
  url: p => `meetings/${p.id}/settings`,
  method: 'post',
  successAction: actions.updateMeetingSettingsSuccess,
  failureAction: actions.updateMeetingSettingsFailed,
});

const deleteMeeting = createRequestSaga({
  actionType: actions.deleteMeetingRequest.type,
  url: p => `meetings/${p.id}`,
  method: 'delete',
  successAction: actions.deleteMeetingSuccess,
  failureAction: actions.deleteMeetingFailed,
});

const saveDocument = createUploadRequestSaga({
  actionType: actions.saveDocumentRequest.type,
  getURL: ({ meetingId, category }) =>
    `meetings/${meetingId}/document/${category}`,
  getMethod: payload => 'post',
  progressAction: actions.saveDocumentProgress,
  successAction: actions.saveDocumentSuccess,
  failureAction: actions.saveDocumentFailed,
});

const deleteDocument = createRequestSaga({
  actionType: actions.deleteDocumentRequest.type,
  url: ({ meetingId, documentId }) =>
    `meetings/${meetingId}/document/${documentId}`,
  method: 'delete',
  successAction: actions.deleteDocumentSuccess,
  failureAction: actions.deleteDocumentFailed,
});

const reorderDocuments = createRequestSaga({
  actionType: actions.reorderDocumentsRequest.type,
  url: ({ meetingId }) => `meetings/${meetingId}/reorder-documents`,
  method: 'post',
  data: ({ oldIndex, newIndex }) => ({ oldIndex, newIndex }),
  successAction: actions.reorderDocumentsSuccess,
  failureAction: actions.reorderDocumentsFailed,
});

const createMeetingUpdate = createRequestSaga({
  actionType: actions.createMeetingUpdateRequest.type,
  url: ({ meetingId }) => `meetings/${meetingId}/updates`,
  method: 'post',
  successAction: actions.createMeetingUpdateSuccess,
  failureAction: actions.createMeetingUpdateFailed,
});

const deleteMeetingUpdate = createRequestSaga({
  actionType: actions.deleteMeetingUpdateRequest.type,
  url: ({ meeting_id, meeting_update_id }) =>
    `meetings/${meeting_id}/updates/${meeting_update_id}`,
  method: 'delete',
  successAction: actions.deleteMeetingUpdateSuccess,
  failureAction: actions.deleteMeetingUpdateFailed,
});

const createMeeting = createRequestSaga({
  actionType: actions.createMeetingRequest.type,
  url: p => `meetings`,
  method: 'post',
  data: p => p,
  successAction: actions.createMeetingSuccess,
  failureAction: actions.createMeetingFailed,
});

const createCommittee = createRequestSaga({
  actionType: actions.createCommitteeRequest.type,
  url: p => `committees`,
  method: 'post',
  data: p => p,
  successAction: actions.createCommitteeSuccess,
  failureAction: actions.createCommitteeFailed,
});

export function* meetingsSaga() {
  yield takeLatest(actions.loadMeetingsRequest.type, loadMeetings);
  yield takeLatest(actions.loadMeetingRequest.type, loadMeeting);
  yield takeLatest(
    actions.updateMeetingSettingsRequest.type,
    updateMeetingSettings,
  );
  yield takeLatest(actions.deleteMeetingRequest.type, deleteMeeting);

  yield takeLatest(actions.saveDocumentRequest.type, saveDocument);
  yield takeLatest(actions.deleteDocumentRequest.type, deleteDocument);
  yield takeLatest(actions.reorderDocumentsRequest.type, reorderDocuments);
  yield takeLatest(actions.createCommitteeRequest.type, createCommittee);
  yield takeLatest(actions.createMeetingRequest.type, createMeeting);

  yield takeLatest(
    actions.createMeetingUpdateRequest.type,
    createMeetingUpdate,
  );

  yield takeLatest(
    actions.deleteMeetingUpdateRequest.type,
    deleteMeetingUpdate,
  );
}
