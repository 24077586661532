/**
 *
 * TitledField
 *
 */
import * as React from 'react';
import { ReactNode } from 'react';
import {
  Box,
  Grid,
  BoxProps,
  Typography,
  TypographyVariant,
} from '@mui/material';

interface Props extends Pick<BoxProps, 'sx'> {
  title: ReactNode;
  children: ReactNode;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl';
  width?: 1 | 2 | 3 | 4 | 5 | 6;
  visible?: boolean;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  helperText?: string;
  variant?: TypographyVariant;
  titleColour?: 'common.black' | 'primary.main' | 'textSecondary';
}

export function TitledField(props: Props) {
  const {
    title,
    children,
    breakpoint = 'md',
    width = 2,
    visible = true,
    direction = 'column',
    helperText,
    titleColour = 'common.black',
    variant = 'h5',
    ...rProps
  } = props;

  if (!visible) return null;

  return (
    <Box {...rProps}>
      <Grid container direction={direction}>
        <Grid item xs={12} {...{ [breakpoint]: width }}>
          <Typography variant={variant} color={titleColour}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} {...{ [breakpoint]: 12 - width }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
