import { Button, ButtonProps, Typography } from '@mui/material';
import React from 'react';
import { PlusIcon } from '../assets/PlusIcon';

type Props = ButtonProps & {
  text?: string;
  addDashedBorder?: boolean;
};

export function AddNewButton(props: Props) {
  const {
    text = 'Add a new risk here',
    addDashedBorder = true,
    sx,
    ...rProps
  } = props;
  const borderStyles = addDashedBorder && '1px dashed rgb(64, 145, 247, 0.5)';
  return (
    <Button
      fullWidth
      variant="text"
      startIcon={<PlusIcon />}
      sx={{
        display: 'flex',
        p: 2,
        justifyContent: 'start',
        border: borderStyles,
        '&:hover': {
          textDecoration: 'none',
          border: addDashedBorder && '1px dashed rgb(64, 145, 247, 1)',
        },
        ...sx,
      }}
      {...rProps}
    >
      <Typography sx={{ px: 1 }}>{text}</Typography>
    </Button>
  );
}
