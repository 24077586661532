/**
 *
 * Committees
 *
 */
import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadCommittees } from './slice/selectors';
import { useCommitteesSlice } from './slice/hook';
import { useHasChanged } from 'utils/usePrevious';
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { ActionButton } from 'app/components/ActionButton';
import { getInitials } from 'utils/initials';
import key from 'weak-key';
import { RiAddCircleLine, RiArrowRightSLine } from 'react-icons/ri';
import { useEffectOnce } from 'utils/useEffectOnce';
import {
  CommitteeFormWidget,
  useCommitteeFormWidget,
} from 'app/widgets/CommitteeFormWidget';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { SectionHeader } from 'app/components/SectionHeader';
import { DashedButton } from 'app/components/DashedButton';
import { useLoadCommittees } from 'utils/useLoadCommittees';

interface Props {
  organisation: IOrganisation;
}

export const List = memo(
  (props: Props) => {
    const { organisation } = props;

    const { reloadCommittees, loadCommittees } = useLoadCommittees(
      organisation.id,
    );

    const dispatch = useDispatch();

    const { open } = useCommitteeFormWidget();

    const { hasPermission } = useHasPermission();

    const loadCommitteesLoadingChanged = useHasChanged(loadCommittees.loading);
    const organisationChanged = useHasChanged(organisation);

    useEffect(() => {
      if (loadCommitteesLoadingChanged && !loadCommittees.loading) {
        if (loadCommittees.error) {
          // todo - redirect away from page (and log?)
          if (loadCommittees.error.code === 401) return;
        }
      }
      if (organisationChanged && organisation.id) {
        reloadCommittees();
      }
    });

    useEffectOnce(() => {
      reloadCommittees();
    });

    const canManageCommittees = hasPermission(
      'manage-committees',
      'organisation',
      organisation.id,
    );

    if (!loadCommittees.data.length && loadCommittees.loading) {
      return <LoadingIndicator />;
    }

    return (
      <>
        {!!loadCommittees.data.length ? (
          <>
            <Typography variant="h3">My committees</Typography>
            <Typography>
              {canManageCommittees
                ? 'View an existing committee or create a new one.'
                : 'View a committee that you are part of.'}
            </Typography>
          </>
        ) : (
          <>
            {canManageCommittees ? (
              <>
                <DashedButton
                  title="No committees"
                  onClick={() => open({ organisation_id: organisation.id })}
                  description={"You haven't created any committees."}
                  ctaText={'Create one now'}
                />
                <CommitteeFormWidget
                  onChange={() => {
                    reloadCommittees();
                    dispatch(authProviderActions.loadUserDataRequest());
                  }}
                />
              </>
            ) : (
              <DashedButton
                title="No committees"
                description={
                  "You haven't been assigned to a committee yet. If you think this is a mistake, please contact your board administrator."
                }
              />
            )}
          </>
        )}

        <Stack spacing={1}>
          {loadCommittees.data.map(c => (
            <ActionButton
              variant={'contained'}
              fullWidth
              to={`${c.id}`}
              key={key(c)}
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ width: '100%', textAlign: 'left' }}
                spacing={3}
              >
                <Box sx={{ width: 40 }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                    }}
                    alt={c.name}
                  >
                    {getInitials(c.name)}
                  </Avatar>
                </Box>
                <Box>
                  <Box>
                    <Typography variant="h3">{c.name}</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.9 }}>
                      {c.member_count}{' '}
                      {`member${c.member_count !== 1 ? 's' : ''}`}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </ActionButton>
          ))}
        </Stack>
      </>
    );
  },
  (p, n) => p.organisation.id === n.organisation.id,
);
