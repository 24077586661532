/**
 *
 * RiskAreaForm
 *
 */
import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { RiCheckFill, RiCloseLine, RiDeleteBackLine } from 'react-icons/ri';
import { TitledField } from 'app/components/TitledField';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { IRiskArea } from 'types/types';
import * as Yup from 'yup';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  onClose: () => void;
  risk_area?: IRiskArea;
  onSave: (payload: Partial<IRiskArea>) => void;
  saving: boolean;
  onDelete?: (payload: IRiskArea) => void;
  existingRiskCategories: IRiskArea[];
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
});

export function RiskAreaForm(props: Props) {
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const {
    risk_area,
    onSave,
    onClose,
    saving,
    onDelete,
    existingRiskCategories,
  } = props;

  return (
    <Formik
      validationSchema={schema}
      initialValues={
        {
          code: undefined,
          icon: 'default',
          name: '',
          ...risk_area,
        } as IRiskArea
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const isDuplicate = existingRiskCategories.some(
          category => category.name.toLowerCase() === values.name.toLowerCase(),
        );
        if (isDuplicate) {
          setDuplicateNameError(true);
          setSubmitting(false);
        } else {
          setDuplicateNameError(false);
          onSave({
            ...(values as Partial<IRiskArea>),
          });
        }
      }}
    >
      {formik => {
        const { setSubmitting, values, isSubmitting, errors } = formik;

        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {risk_area ? (
                    <>Editing {risk_area.name} </>
                  ) : (
                    'Create risk category'
                  )}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant={'h3'}>
                      Risk category details
                    </Typography>
                    <RequiredFieldIndicator />
                  </Box>
                  <TitledField width={3} title={'Risk category name *'}>
                    <Field
                      component={TextField}
                      id="name"
                      name="name"
                      label="Enter a risk category"
                      error={duplicateNameError}
                      helperText={
                        duplicateNameError && 'This category already exists'
                      }
                      fullWidth
                    />
                  </TitledField>
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions sx={{}}>
              {!!(risk_area && onDelete) && (
                <Button
                  variant={'text'}
                  color={'error'}
                  startIcon={<RiDeleteBackLine />}
                  disabled={saving || isSubmitting}
                  sx={{ mr: 'auto' }}
                  onClick={() => onDelete(risk_area)}
                >
                  Delete risk category
                </Button>
              )}
              <CancelAndSaveButtons
                saveIcon={<RiCheckFill />}
                disabled={isSubmitting || saving}
                onCancel={onClose}
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
