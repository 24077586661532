import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { RiAddCircleFill, RiShareForward2Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { IOrganisation } from 'types/types';
import { ActionRegister } from 'app/widgets/ActionRegister';

interface Props {
  organisation: IOrganisation;
}

export function OpenAndProgress(props: Props) {
  const { organisation } = props;

  return (
    <Stack spacing={6}>
      <Typography variant="h3">All actions</Typography>
      <ActionRegister viewMode={'default'} organisation_id={organisation.id} />
    </Stack>
  );
}
