/**
 *
 * OrganisationForm
 *
 */
import * as React from 'react';
import { Stack, Typography, Grid, Button, Box } from '@mui/material';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { IAbridgedOrganisation } from 'types/types';
import { TitledField } from 'app/components/TitledField';
import {
  RiBuilding2Line,
  RiGlobeLine,
  RiHome5Line,
  RiImageAddFill,
} from 'react-icons/ri';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  organisation: IAbridgedOrganisation;
  onSave: (payload: IAbridgedOrganisation) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  premises: Yup.string(),
  address_line_1: Yup.string().required('This field is required'),
  address_line_2: Yup.string(),
  locality: Yup.string().required('This field is required'),
  region: Yup.string(),
  country: Yup.string().required('This field is required'),
  postal_code: Yup.string().required('This field is required'),
});

export function OrganisationForm(props: Props) {
  const { organisation, onSave, saving } = props;

  return (
    <Stack>
      <Typography>
        Update your organisation's details. These details will be visible to all
        members of your Organisation.
      </Typography>
      <Formik
        validationSchema={schema}
        initialValues={
          organisation
            ? {
                ...organisation,
              }
            : ({
                id: undefined,
                name: '',
                premises: '',
                address_line_1: '',
                address_line_2: '',
                locality: '',
                region: '',
                country: '',
                postal_code: '',
                url: '',
              } as IAbridgedOrganisation)
        }
        validateOnChange
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSave({ ...values });
        }}
      >
        {formik => {
          const { setSubmitting, dirty, isSubmitting } = formik;
          if (isSubmitting && !saving) setSubmitting(false);
          return (
            <Form>
              <Stack spacing={3}>
                <RequiredFieldIndicator />
                <TitledField title={'Name'}>
                  <Field
                    component={TextField}
                    id="name"
                    name="name"
                    label="Organisation name *"
                    fullWidth
                  />
                </TitledField>
                <TitledField title={'Address'}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="premises"
                        name="premises"
                        label="Premises"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="address_line_1"
                        name="address_line_1"
                        label="Address Line 1  *"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="address_line_2"
                        name="address_line_2"
                        label="Address Line 2"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="locality"
                        name="locality"
                        label="Town / City *"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="region"
                        name="region"
                        label="County"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="country"
                        name="country"
                        label="Country *"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                      <Field
                        component={TextField}
                        id="postal_code"
                        name="postal_code"
                        label="Post Code *"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </TitledField>
              </Stack>
              <Stack direction={'row'} justifyContent={'flex-end'}>
                <Button
                  variant={'outlined'}
                  type={'reset'}
                  disabled={!dirty || isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  disabled={!dirty || isSubmitting || saving}
                >
                  Save
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
}
