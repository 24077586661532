/**
 *
 * SelectPlan
 *
 */
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { apiRequest } from 'utils/request';
import { RiCheckboxCircleLine, RiStarFill } from 'react-icons/ri';
import { useSnackbar } from 'notistack';

interface Props {
  planDetails?: {
    planHash?: string;
    plan_id?: number;
    name?: string;
    partner_name?: string;
  };

  onSelect: (payload: {
    planHash?: string;
    plan_id?: number;
    name?: string;
    partner_name?: string;
  }) => void;
  saving?: boolean;
  onDismiss?: () => void;
}

function PlanButton(props: {
  name: ReactNode;
  description: ReactNode;
  selected: boolean;
  pro?: boolean;
  onClick: () => void;
}) {
  const { name, description, selected, onClick, pro } = props;
  return (
    <Button
      onClick={onClick}
      sx={{
        height: '100%',
        width: '100%',
        display: 'block',
        borderColor: 'grey.400',
        py: 4,
      }}
      variant={selected ? 'contained' : 'outlined'}
      color={selected ? 'secondary' : 'primary'}
    >
      <Stack justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'h5'}>{name}</Typography>
        <Box>{description}</Box>
      </Stack>
    </Button>
  );
}

export function SelectPlan(props: Props) {
  const { onSelect, onDismiss, saving = false, planDetails = {} } = props;

  const [planItem, setPlanItem] = useState<'standard' | 'code' | null>(
    planDetails.planHash ? 'code' : 'standard',
  );
  const [code, setCode] = useState<string | null>(null);
  const [planHash, setPlanHash] = useState<string | null>(
    planDetails.planHash || null,
  );
  const [partnerPlanName, setPartnerPlanName] = useState<string | null>(
    planDetails.name || null,
  );
  const [partnerName, setPartnerName] = useState<string | null>(
    planDetails.partner_name || null,
  );

  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(
    planDetails.plan_id,
  );

  const snackbar = useSnackbar();

  const [checking, setChecking] = useState<boolean>(false);

  const checkCode = useCallback(async () => {
    if (code && planItem === 'code') {
      setChecking(true);
      try {
        const res = await apiRequest({
          url: 'organisations/check-plan-code',
          method: 'post',
          data: {
            code,
          },
        });
        setPlanHash(res.data.planHash || []);
        setPartnerPlanName(res.data.name || '');
        setPartnerName(res.data.partner_name || '');
      } catch (e) {
        snackbar.enqueueSnackbar('Code not found', { variant: 'error' });
      } finally {
        setChecking(false);
      }
    }
  }, [code, planItem]);

  useEffect(() => {
    if (planItem === 'standard') {
      setCode(null);
      setChecking(false);
      setPlanHash(null);
    } else {
      setSelectedPlanId(null);
    }
  }, [planItem]);

  useEffect(() => {
    return () => {
      snackbar.closeSnackbar();
    };
  }, [snackbar]);

  const [name, partner_name] = useMemo(() => {
    if (selectedPlanId) {
      const partner_name = 'Governance360';
      switch (selectedPlanId) {
        case 1:
          return ['Free', partner_name];
        case 2:
          return ['Standard', partner_name];
        case 3:
          return ['Pro', partner_name];
        default:
          return ['', partner_name];
      }
    }

    return [partnerPlanName, partnerName];
  }, [selectedPlanId, partnerPlanName, partnerName]);

  return (
    <Box>
      <Stack spacing={4}>
        <Typography variant={'body1'}>
          Choose the Governance360 plan you want to start with (you can change
          later on), or enter a partner code if you have been provided with one
          to use:
        </Typography>
        <Typography variant={'h5'}>Select your plan</Typography>
        <RadioGroup
          name={'plan-item'}
          value={planItem}
          onChange={e => setPlanItem(e.target.value as 'standard' | 'code')}
        >
          <Stack spacing={4}>
            <Paper
              sx={{
                p: 4,
                border: 2,
                borderColor:
                  planItem === 'standard' ? 'primary.main' : 'grey.400',
              }}
              elevation={0}
            >
              <Stack>
                <FormControlLabel
                  value="standard"
                  control={<Radio color={'primary'} />}
                  label={
                    <>
                      I’d like to use a <strong>Governance360 plan</strong>
                    </>
                  }
                />
                <Typography variant={'body2'}>
                  Choose from the Governance360 range of plans.
                </Typography>

                {planItem === 'standard' && (
                  <Box>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'stretch'}
                    >
                      {/*/
                      <Grid item xs={12} md={4}>
                        <PlanButton
                          onClick={() => setSelectedPlanId(1)}
                          selected={selectedPlanId === 1}
                          name={'Free'}
                          description={
                            'Our Free plan – ideal for start-ups and micro organisations.'
                          }
                        />
                      </Grid> /*/}
                      <Grid item xs={12} md={6}>
                        <PlanButton
                          onClick={() => setSelectedPlanId(2)}
                          selected={selectedPlanId === 2}
                          name={'Standard'}
                          description={
                            <Typography variant="body2">
                              Workflow only - Boards, Risk and more
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PlanButton
                          onClick={() => setSelectedPlanId(3)}
                          selected={selectedPlanId === 3}
                          name={'Professional'}
                          pro
                          description={
                            <>
                              <Typography variant="body2">
                                Our Standard package PLUS Academy.
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontStyle: 'italic' }}
                              >
                                (Where most people start with us)
                              </Typography>
                            </>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Stack>
            </Paper>

            <Paper
              sx={{
                p: 4,
                border: 2,
                borderColor: planItem === 'code' ? 'primary.main' : 'grey.400',
              }}
              elevation={0}
            >
              <Stack>
                <FormControlLabel
                  value="code"
                  control={<Radio color={'primary'} />}
                  label={
                    <>
                      I have a <strong>Partner Plan link</strong>
                    </>
                  }
                />
                <Typography variant={'body2'}>
                  These plans contain bespoke partner features. Please enter the
                  partner code that has been emailed to you below
                </Typography>
                {planItem === 'code' && (
                  <Box>
                    <Stack direction={'row'}>
                      <TextField
                        sx={{
                          '& .MuiInputBase-root': { backgroundColor: 'white' },
                        }}
                        value={code || ''}
                        onChange={e => setCode(e.target.value)}
                        disabled={!!code && !!planHash}
                      />
                      {planHash ? (
                        <Button
                          color={'success'}
                          variant={'contained'}
                          disabled={!planHash}
                          endIcon={<RiCheckboxCircleLine />}
                        >
                          Verified
                        </Button>
                      ) : (
                        <Button
                          variant={'contained'}
                          disabled={!code || checking}
                          onClick={checkCode}
                        >
                          Verify code
                        </Button>
                      )}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Paper>

            <Divider />
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button
                onClick={onDismiss}
                disabled={saving}
                variant={'text'}
                size={'small'}
              >
                Back: organisation details
              </Button>
              <Button
                disabled={
                  (planItem === 'standard' && !selectedPlanId) ||
                  !planItem ||
                  (planItem === 'code' && !planHash) ||
                  saving
                }
                variant={'contained'}
                onClick={() =>
                  onSelect({
                    planHash,
                    plan_id: selectedPlanId,
                    name,
                    partner_name,
                  })
                }
              >
                Next: confirm details
              </Button>
            </Stack>
          </Stack>
        </RadioGroup>
      </Stack>
    </Box>
  );
}
