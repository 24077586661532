import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ButtonProps {
  title: string;
  ctaText?: ReactNode;
  description: ReactNode;
  path?: string;
}

export function DashedButton(props: Props) {
  const { title, path, ctaText, description, onClick, ...rProps } = props;

  const isInteractive = !!path || !!onClick;

  return (
    <Button
      variant="outlined"
      fullWidth
      component={path ? Link : 'button'}
      {...(path ? { to: path } : {})}
      onClick={onClick}
      sx={{
        my: 4,
        p: 4,
        pointerEvents: !isInteractive && 'none',
        borderStyle: 'dashed',
        borderColor: 'primary.main',
      }}
      {...rProps}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        textAlign={'center'}
        spacing={4}
      >
        <Typography variant="h3" sx={{ color: 'primary.main' }}>
          {title}
        </Typography>
        <Box sx={{ color: 'text.secondary' }}>{description}</Box>
        {ctaText && <Typography color="primary">{ctaText}</Typography>}
      </Stack>
    </Button>
  );
}
